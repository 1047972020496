import React from "react";
import "./Bullets.module.scss";

export const Bullets = (props) => {
  const { items, selected, onSelect } = props;
  return (
    <div className="slider__bullets">
      {items.map((v, i) => {
        const selectedClass = i === selected ? " selected" : "";
        return (
          <div
            key={i}
            onClick={() => onSelect(i)}
            className={"slider__bullet" + selectedClass}
          ></div>
        );
      })}
    </div>
  );
};
