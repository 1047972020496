import React, {useEffect, useState} from "react";
import queryString from "query-string";
import { useRouter } from "../../hooks/useRouter";
import { Page } from "./Page";
import {DeliverySelectDropdown} from "../../components/DeliverySelectDropdown";
import { MetaWrapper } from "../../components/Meta";
import { dataSourceV2 } from "commons-ui/core/DataSource";

import { useDispatch, useStore } from "../../context/state";

import "./MenuView.module.scss";

export default function MenuMainView({ categoryList, productDictByCategory }) {
    const [deliveryOpen, setDeliveryOpen] = useState(null);
    const [storeSettings, setStoreSettings] = useState({})
    const { dispatch, apiPath, mediaPath, ecommerceStoreId } = useStore();

    console.log(2, categoryList, productDictByCategory)

  const location = useRouter();

  const filters = queryString.parse(location.search);

  const requestStoreSettings = () => {
    dataSourceV2(
      `ecommerce_store_settings_get?ecommerce_store_id=${ecommerceStoreId}`,
      { url: apiPath }
    ).then(v => {
      if (v.item) {
        setStoreSettings(v.item)
      }
    })
  }

  const onItemClick = (id, e, item, isDetails) => {
    //dispatch("/product/" + id + "?" + queryString.stringify(filters));
    if (isDetails) {
        dispatch(location.pathname + "?" + queryString.stringify({...filters, show_product_general: item.slug || id}));
    } else {
        setDeliveryOpen(e);
    }
    //dispatch(location.pathname + "?" + queryString.stringify({...filters, show_product: id, select_delivery: "true"}));
  };

  useEffect(() => {
    requestStoreSettings()
  }, [])

  return (
    <MetaWrapper
      meta={{
        title: "Piroshky Piroshky Bakery - Home",
        description:
          "Famous Beef & Cheese, Smoked salmon Pate. Traditional Piroshky. Apple Cinnamon Roll, Chocolate Cream Hazelnut. Borscht.",
      }}
    >
      <Page
        categoryList={categoryList}
        productDictByCategory={productDictByCategory}
        filters={filters}
        onItemClick={onItemClick}
        storeSettings={storeSettings}
      />
  <DeliverySelectDropdown open={deliveryOpen}
                          onClose={() => setDeliveryOpen(null)}
                          itemId="ecommerce_store_delivery_option_id"
                          modelName="ecommerce_store_delivery_option"/>
    </MetaWrapper>
  );
}
