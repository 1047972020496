import React from "react";

import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import "./ForwardButton.module.scss";

export const ForwardButton = (props) => {
  const { isActive, onClick } = props;
  return (
    <div className="carousel-next-button show" onClick={onClick}>
      <svg
        className={`progress ${isActive ? "active" : ""}`}
        viewBox="0 0 120 120"
      >
        <circle cx="60" cy="60" r="54"></circle>
      </svg>
      <KeyboardArrowRight className="arrow" />
    </div>
  );
};
