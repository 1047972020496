import React, { useEffect, useState } from "react";
import { useRouter } from "../../../hooks/useRouter";

import { ForwardButton } from "./ForwardButton";
import { Bullets } from "./Bullets";

import { FirstSlide } from "./Slides/FirstSlide";
import { SecondSlide } from "./Slides/SecondSlide";
import { CustomFirstSlide } from "./Slides/CustomFirstSlide";
import { CustomSecondSlide } from "./Slides/CustomSecondSlide";

import { useStore } from "../../../context/state";

import { useCarousel } from "commons-ui/core/Carousel";

import "./Carousel.module.scss";
import "./Slides/Slides.module.scss";

const structure = {};

const getProps = (settings, index) => {
  index = index + 1
  index = index.toString()
  if (index === "0" || index === "1") {
    index = ""
  }
  return ({
    image: settings[`banner${index}_image`], 
    imageMobile: settings[`banner${index}_image_mobile`], 
    isEnabled: settings[`is_banner${index}_enabled`], 
    video: settings[`banner${index}_video`], 
    url: settings[`banner${index}_url`], 
    videoMobile: settings[`banner${index}_video_mobile`], 
    backgroundColor: settings[`banner${index}_background_color`]
  })
}

function initSlides(unique, settings) {
  const slides = []
  for (let i = 0; i < 4; i++) {
    const props = getProps(settings, i)
    if (props.isEnabled) {
      slides.push({ component: <CustomFirstSlide {...props}/> })
    }
  }
  return slides;
}

function AddExtraProps(Component, extraProps) {
  return <Component.type {...Component.props} {...extraProps} />;
}

export const Carousel = (props) => {
  const { unique, storeSettings } = props;

  const { dispatch, isMobile, mediaPath } = useStore();

  const location = useRouter();

  const slides = initSlides(unique, storeSettings)

  const length = slides.length;

  const [slideNumber, setSlideNumber, handlers, style] = useCarousel(
    length,
    10000
  );
  const [animate50, setAnimate50] = useState(false);
  const [loading, setLoading] = useState(true);
  const slide = slides[slideNumber] || {};

  const animatedInitialClass = loading ? "" : "show";

  useEffect(() => {
    setAnimate50(false);
    const id = setTimeout(() => setAnimate50(true), 50);
    return () => clearTimeout(id);
  }, [slideNumber]);

  useEffect(() => {
    const id = setTimeout(() => {
      setLoading(false);
    }, 100);
    return () => clearTimeout(id);
  }, []);

  if (!length) {
    return null;
  }

  return (
    <div id="carousel-section" className="">
      <div
        className={
          "carousel-widget u-overflowHidden u-relative u-borderRadius3 " +
          (slide.theme || "")
        }
        style={{ paddingBottom: 80, height: props.height }}
      >
        <div
          className="u-absolute u-flexCenter"
          {...handlers}
          style={{ ...style, height: "calc(100% - 80px)" }}
        >
          <div className={"slide flexCenter selected"}>
            {AddExtraProps(slides[length - 1].component, {
              animatedInitialClass,
              location,
              isMobile,
              dispatch,
              mediaPath,
              storeSettings
            })}
          </div>
          {slides.map((v, i) => {
            const selectedClass = i === slideNumber ? " selected" : "";
            return (
              <div className={"slide flexCenter selected"}>
                {AddExtraProps(v.component, {
                  animatedInitialClass,
                  location,
                  isMobile,
                  dispatch,
                  mediaPath,
                  storeSettings
                })}
              </div>
            );
          })}
          <div className={"slide flexCenter selected"}>
            {AddExtraProps(slides[0].component, {
              animatedInitialClass,
              location,
              isMobile,
              dispatch,
              mediaPath,
              storeSettings
            })}
          </div>
        </div>

        {length > 1 ? (
          <ForwardButton
            isActive={animate50}
            onClick={() => setSlideNumber((slideNumber + 1) % length)}
          />
        ) : null}
        {length > 1 ? (
          <Bullets
            selected={slideNumber}
            onSelect={setSlideNumber}
            items={slides.map((v, i) => ({ value: i }))}
          />
        ) : null}
        {loading ? (
          <div className="u-absolute u-sizeFull u-zIndex3 u-backgroundWhite u-flexCenter u-justifyContentCenter">
            {/*<Loader/>*/}
          </div>
        ) : null}
      </div>
    </div>
  );
};
